<template>
  <div>
    <table-page data-name="list"
                ref="table"
                @on-success="fetchSuccess"
                :params="{ activity_id: $route.query.id }"
                :search="search"
                :request="getMemberAppointmentRecord">
      <template slot="button">
        <FileExport :params="exportParams"
                    v-if="exportParams">导出数据</FileExport>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码"></el-table-column>
      <el-table-column prop="id_code"
                       show-overflow-tooltip
                       label="身份证号"></el-table-column>
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="OPENID"></el-table-column>
      <el-table-column prop="prize_name"
                       show-overflow-tooltip
                       label="预约网点">
        <template slot-scope="scope">
          <span>
            {{
            `${scope.row.province}${scope.row.city || ''}${scope.row
            .district || ''}${scope.row.net || ''}`
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="project_name"
                       show-overflow-tooltip
                       label="预约项目"></el-table-column>
      <el-table-column prop="appointment_at"
                       show-overflow-tooltip
                       label="预约提交时间"></el-table-column>
      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="关联员工"></el-table-column>
      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       label="员工所属网点">
        <template slot-scope="scope">
          <span>
            {{
            `${scope.row.marketer_province}${scope.row.marketer_city ||
            ''}${scope.row.marketer_district || ''}${scope.row
            .marketer_net || ''}`
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="setAppoint(scope)">编辑</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetAppoint ref="setAppoint"
                :aid="$route.query.id"
                @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import SetAppoint from './components/SetAppoint'
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import { getMemberAppointmentRecord } from '@/api/market'
export default {
  name: 'MemberAppointRecord',
  data () {
    return {
      getMemberAppointmentRecord,
      dialogVisible: false,
      luckyQrcodesExport_url: '',
      luckyLogExport_url: '',
      exportParams: null,
      search: [{ type: 'area' }, {
        type: 'input',
        name: '手机号码',
        placeholder: '请输入手机号码',
        key: 'tel',
        value: ''
      }
      ]
    }
  },
  components: {
    TablePage,
    FileExport,
    SetAppoint
  },
  methods: {
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    exportData (url) {
      window.open(url, '_blank')
    },
    setAppoint (scope) {
      this.$refs.setAppoint.showDialog(scope)
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped></style>

<template>
  <div class="">
    <el-dialog title="设置选项"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               width="50%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="预约网点"
                      prop="net">
          <Area v-model="areaAddress"
                @change="areaChange" />
        </el-form-item>
        <el-form-item label="选项名称"
                      prop="new_network_id">
          <el-select v-model="form.new_network_id"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="tr">
          <el-button type="primary"
                     class="mt-15"
                     @click="submitForm('form')">立即{{form.id!==''?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Area from '@/components/Area'
import { setMemberAppointmentUser, getMemberAppointmentNets } from '@/api/market'
export default {
  props: ['aid'],
  data () {
    return {
      areaAddress: {},
      options: [],
      dialogVisible: false,
      imgList: [],
      form: {
        id: '',
        province: '',
        city: '',
        district: '',
        net: '',
        new_network_id: '',
        activity_id: ''
      },
      rules: {
        new_network_id: [
          { required: true, message: '请选择选项', trigger: 'change' }
        ],
        net: [
          { required: true, message: '请选择到网点', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area
  },
  methods: {

    async areaChange (address) {
      this.form.new_network_id = ''
      this.form.province = address.province
      this.form.city = address.city
      this.form.district = address.district
      this.form.net = address.net
      if (address.net) {
        const res = await getMemberAppointmentNets({
          ...address,
          activity_id: this.aid,
          size: 1000
        })
        if (res.meta.code === 0) {
          const options = []
          res.data.list.map((item) => {
            options.push({
              value: item.id,
              label: item.options_name
            })
          })
          this.options = options
        } else {

        }
      }
    },
    showDialog (scoped) {
      this.dialogVisible = true
      this.areaAddress = {
        province: scoped.row.province,
        city: scoped.row.city,
        district: scoped.row.district,
        net: scoped.row.net
      }

      this.$nextTick(() => {
        if (scoped.row) {
          const formData = JSON.parse(JSON.stringify(scoped.row))
          this.form = {
            new_network_id: formData.network_id,
            id: formData.id,
            province: formData.province,
            city: formData.city,
            district: formData.district,
            net: formData.net,
            activity_id: this.aid

          }
        }
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setMemberAppointmentUser(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
